import '../../App.css';
import './galleryUtil22.css';
import { Row, Col, Container } from 'react-bootstrap';
import coin_1gm from '../../assets/images/gallery22/coin_1gms.jpg'
import coin_2gm from '../../assets/images/gallery22/coin_2gms.jpg'
import coin_4gm from '../../assets/images/gallery22/coin_4gms.jpg'
import coin_5gm from '../../assets/images/gallery22/coin_5gms.jpg'
import coin_8gm from '../../assets/images/gallery22/coin_8gms.jpg'
import coin_10gm from '../../assets/images/gallery22/coin_10gms.jpg'
import { useEffect, useState } from 'react';
import classes from "classnames";
import { SideBySideMagnifier } from "react-image-magnifiers";

function GalleryUtil22(props) {
    const fixed = false
    const [updatedKey,setUpdatedKey] =useState(0);
    const [currImg, setCurrImg] = useState();
    useEffect(()=>{
        if(props.weight){
            switch(props.weight){
                case "1gms":
                    setCurrImg(coin_1gm);
                    break;
                case "2gms":
                    setCurrImg(coin_2gm);
                    break;
                case "4gms":
                    setCurrImg(coin_4gm);
                    break;
                case "5gms":
                    setCurrImg(coin_5gm);
                    break;
                case "8gms":
                    setCurrImg(coin_8gm);
                    break;
                case "10gms":
                    setCurrImg(coin_10gm);
                    break;
                default :
                    setCurrImg(coin_10gm);
                    break;
            }
        }
    })
    
    const minWidth = 50;
    const minHeight = 50
    const maxWidth = 250;
    const maxHeight = 250;
    const updateCurrImg = (newImg) =>{
        setTimeout(()=>{
            setCurrImg(newImg);
            setUpdatedKey(updatedKey+1)
        },0)
    }
    const backToNormal = ()=>{
        // setCurrImg(image_1);
    }
    return (
        <Row>
            <Col className="coinSmallImg" sm={3}>
                {/* <Row><Col><img src={image_1} height={minHeight} widht={minWidth} onMouseMove={()=>updateCurrImg(image_1)}/></Col></Row>
                <Row><Col><img src={image_2} height={minHeight} widht={minWidth} onMouseMove={()=>updateCurrImg(image_2)}/></Col></Row> */}
                <Row><Col><img src={currImg} height={minHeight} widht={minWidth} onMouseMove={()=>updateCurrImg(currImg)}/></Col></Row>
            </Col>
            <Col sm={6}>
                <div className="coinBigImg" onMouseOut={(e)=>backToNormal()}>
                    <SideBySideMagnifier
                    className={classes("magnifier", { fixed })}
                    imageSrc = {currImg}
                    key = {updatedKey}
                    touchActivation = "tap"
                    alwaysInPlace = {true}
                    transitionSpeed = {0.7}
                    />
                </div>
            </Col>
            <Col className="coinText" sm={3}>Gold Coin {props.weight}</Col>
        </Row>
    );
}

export default GalleryUtil22;
