import React from 'react';
import ToolTip from './Tooltip';

class ToolTipComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolHoverId: null
    }
  }

  onMouseEnterHandler = (id) => {
    this.setState({
      toolHoverId: id
    })
  }
  onMouseLeaveHandler = (id) => {
    this.setState({
      toolHoverId: null
    })
  }

  render() {
    return (
      <span className="toolComponent">
        {this.props.exclamationIcon ?
          <b className="exclamation-icon"
            onMouseEnter={() => this.onMouseEnterHandler(this.props.id)}
            onMouseLeave={() => this.onMouseLeaveHandler(this.props.id)}>!</b> :
          <i className="orange-bulb"
            onMouseEnter={() => this.onMouseEnterHandler(this.props.id)}
            onMouseLeave={() => this.onMouseLeaveHandler(this.props.id)}>
          </i>}
        <ToolTip data={this.props.data} id={this.props.id} toolHoverId={this.state.toolHoverId} />
      </span>
    )
  }
}

export default ToolTipComponent;