import '../../App.css';
import './galleryUtil24.css';
import { Row, Col, Container } from 'react-bootstrap';
// import image_1 from '../../assets/images/gallery/coin_1.jpeg';
// import image_2 from '../../assets/images/gallery/coin_2.jpeg';
import coin_1gm from '../../assets/images/gallery/coin_1gm.jpg';
import coin_2gm from '../../assets/images/gallery/coin_2gm.jpg';
import coin_5gm from '../../assets/images/gallery/coin_5gm.jpg';
import coin_10gm from '../../assets/images/gallery/coin_10gm.jpg';
import coin_20gm from '../../assets/images/gallery/coin_20gm.jpg';
import coin_50gm from '../../assets/images/gallery/coin_50gm.jpg';
import coin_100gm from '../../assets/images/gallery/coin_100gm.jpg';
import { useEffect, useState } from 'react';
import classes from "classnames";
import { SideBySideMagnifier } from "react-image-magnifiers";

function GalleryUtil24(props) {
    const fixed = false
    const [updatedKey,setUpdatedKey] =useState(0);
    const [currImg, setCurrImg] = useState();
    useEffect(()=>{
        if(props.weight){
            switch(props.weight){
                case "1gms":
                    setCurrImg(coin_1gm);
                    break;
                case "2gms":
                    setCurrImg(coin_2gm);
                    break;
                case "5gms":
                    setCurrImg(coin_5gm);
                    break;
                case "10gms":
                    setCurrImg(coin_10gm);
                    break;
                case "20gms":
                    setCurrImg(coin_20gm);
                    break;
                case "50gms":
                    setCurrImg(coin_50gm);
                    break;
                default :
                    setCurrImg(coin_100gm);
                    break;
            }
        }
    })
    
    const minWidth = 50;
    const minHeight = 50
    const maxWidth = 250;
    const maxHeight = 250;
    const updateCurrImg = (newImg) =>{
        setTimeout(()=>{
            setCurrImg(newImg);
            setUpdatedKey(updatedKey+1)
        },0)
    }
    const backToNormal = ()=>{
        // setCurrImg(image_1);
    }
    return (
        <Row>
            <Col className="coinSmallImg" sm={3}>
                {/* <Row><Col><img src={image_1} height={minHeight} widht={minWidth} onMouseMove={()=>updateCurrImg(image_1)}/></Col></Row>
                <Row><Col><img src={image_2} height={minHeight} widht={minWidth} onMouseMove={()=>updateCurrImg(image_2)}/></Col></Row> */}
                <Row><Col><img src={currImg} height={minHeight} widht={minWidth} onMouseMove={()=>updateCurrImg(currImg)}/></Col></Row>
            </Col>
            <Col sm={6}>
                <div className="coinBigImg" onMouseOut={(e)=>backToNormal()}>
                    <SideBySideMagnifier
                    className={classes("magnifier", { fixed })}
                    imageSrc = {currImg}
                    key = {updatedKey}
                    touchActivation = "tap"
                    alwaysInPlace = {true}
                    transitionSpeed = {0.7}
                    />
                </div>
            </Col>
            <Col className="coinText" sm={3}>Gold Coin {props.weight}</Col>
        </Row>
    );
}

export default GalleryUtil24;
