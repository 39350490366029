import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import Header from './components/header/header';
import Home from './components/home/home';
import About from './components/about/about';
import Gallery from  './components/gallery/gallery';
import MobileApp from './components/mobileApp/mobileApp';
import BuySell from './components/buySell/buySell';
import Footer from './components/footer/footer';
import Contact from './components/contact/contact';
import BecomeRetailer from './components/becomeRetailer/becomeRetailer';
import TodaysRate from './components/todaysRate/todaysRate';
import LiveRate from './components/liveRate/liveRate';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Header/>
        <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' exact component={About} />
        <Route path='/gallery' exact component={Gallery} />
        <Route path='/mobileApp' exact component={MobileApp} />
        <Route path='/buysell' exact component={BuySell} />
        <Route path='/contact' exact component={Contact} /> 
        <Route path='/becomeRetailer' exact component={BecomeRetailer} /> 
        <Route path='/todaysRate' exact component={TodaysRate}/>
        <Route path='/liveRate' exact component={LiveRate}/>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
