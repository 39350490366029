import '../../App.css'
import './retailer.css';
import React from "react";
import { Col } from 'react-bootstrap';
import { Icon } from '@iconify/react'; 

import { getConfig } from '../../app/configSlice';
import { useSelector } from 'react-redux';

function GetRetailer(props){
    const config = useSelector(getConfig);
    let dataFlag=false;
    let address = "";
    if(props.retailer && props.retailer["id"]){
      dataFlag= true;
      address = props.retailer.address.street +","+props.retailer.address.city+","+props.retailer.address.state
    }
    const openDirections = (mapUrl="",address) =>{
      if(mapUrl){
        window.open(config.getDirection+mapUrl,"_blank")
      }
      else{
        window.open(config.getDirection+address.replace(" ","+"),"_blank")
      }
      
    }
    return(
        <React.Fragment>
          {
            dataFlag?
            <Col  key={"retailer_"+props.retailer["id"]} className="retailerBlock">
            <div className="retailerName"> {props.retailer.company_name}</div>
            <div className="retailerGST">(GST&nbsp;:&nbsp;{props.retailer.gst?props.retailer.gst:"UNAVAILABLE"})</div>
            {props.retailer.email?<div className="retailerDetails">
              <a href="mailto:rbs-coins@gmail.com" title="send mail">
                <Icon icon="bi:envelope" height="1rem" width="1rem"/>
              </a>
              <span>{props.retailer.email}</span>
            </div>:null
            }
            {
            props.retailer.phone_no?<div className="retailerDetails">
              <Icon  icon="bi-phone" height="1rem" width="1rem"/>
                {
                  props.retailer.phone_no.split(",").map((mobNo,ind)=>{
                    // if(ind%2==0 && ind + 1 <props.retailer.phone_no.split(",").length ){
                    //   return <span><a href={"tel:"+mobNo} title={"click to call "+mobNo}>{mobNo}</a> &nbsp;/&nbsp;</span>
                    // }
                    // else{
                    //   return <span><a href={"tel:"+mobNo} title={"click to call "+mobNo}>{mobNo}</a><br/></span>
                    // }
                    if(ind + 1 <props.retailer.phone_no.split(",").length ){
                      return <span><a href={"tel:"+mobNo} title={"click to call "+mobNo}>{mobNo}</a> &nbsp;/&nbsp;</span>
                    }
                    else{
                      return <span><a href={"tel:"+mobNo} title={"click to call "+mobNo}>{mobNo}</a> </span>
                    }
                  })
                }
            </div>:null
            }
            <div className="retailerDetails">
              <span title="Get Directions" className="addressIcon" onClick={()=>openDirections(props.retailer.hasMapUrl=="TRUE" || props.retailer.hasMapUrl==true?props.retailer.map_url:"", props.retailer.company_name+","+address)}><Icon icon="bi:geo-alt" className="addressIcon"  height="1rem" width="1rem" /></span>
              <span>{address}</span>
            </div>
          </Col>:<Col></Col>
          }
        </React.Fragment>
    );
}
export default GetRetailer;