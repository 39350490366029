import '../../App.css';
import './about.css';
import image from '../../assets/images/gold_image_2.jpg';
import { Redirect } from 'react-router-dom';
import {Container} from 'react-bootstrap';
import { getConfig } from '../../app/configSlice';
import { useSelector } from 'react-redux';
import { useState } from 'react'

function About() {
  const config = useSelector(getConfig);
  const [bgHeight,setBgHeight] = useState(window.outerHeight-100)
  const styleObj={
    "height" : bgHeight
  }
  return (
    config && Object.keys(config).length===0?
    <Redirect to="/" />:<div className="about main-outer-container" style={styleObj}>
      <Container>
        <img src={image} width="1100" height="300"/>
        <h3>{config["about"]["header"]}</h3>
        <div className="text">
        {config["about"]["text"]}
        </div>
        
      </Container>
    </div>
  );
}

export default About;