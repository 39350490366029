import UI_CONFIG from '../config/config.json'
export const mapStateToProps = state => {
  return {
    config: state.config,
    retailer:state.retailer,
    todaysRate:state.todaysRate,
    liveRates:state.liveRates
  }
}
export const headerObj = {
  headers : {
    'API-KEY' : 'YWtzZG5zZGZqY2Nuc2RramZhc2ZrbmRzY2FscWFzY21ka3NrZG5jZWlvbmY=',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Content-Type": "application/json"
  }
}
export const getEnvironment = (host) =>{
  if(host.indexOf('localhost')>=0){
    return UI_CONFIG.basepath["local"]
  }
  else if(host.indexOf('rbs-coins-dev')>=0){
    return UI_CONFIG.basepath["dev"]
  }
  else{
    return UI_CONFIG.basepath["prod"]
  }
}