import '../../App.css';
import './gallery.css';
import { Container, Row, Col,Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import GalleryUtil24 from '../galleryUtil24/galleryUtil24';
import GalleryUtil22 from '../galleryUtil22/galleryUtil22';
import GalleryUtil24Coin from '../galleryUtil24Coin/galleryUtil24Coin';
// import { useEffect } from 'react';
import { getConfig, update } from '../../app/configSlice';
import { useSelector,useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import $ from 'jquery';
// import axios from 'axios';

const UI_CONFIG = require("../../config/config.json")

function Gallery() {

  const config = useSelector(getConfig);
  const [currTab, setCurrTab] = useState('GOLD24BAR');

  const changeTab=(event, type)=>{
    $(".switchBtns button.btn").map((i)=>{
        $(".switchBtns button.btn")[i].classList.replace('btn-warning','btn-secondary')
    })
    event.target.classList.replace("btn-secondary",'btn-warning');
    setCurrTab(type);
  }

  return (
    config && Object.keys(config).length===0?
    <Redirect to="/" />:<div className="gallery main-outer-container">
      <Container>
        <Row>
        <Col sm={3}></Col>
          <Col sm={6} className="switchBtns">
            <Button variant='warning' onClick={(e)=>changeTab(e,'GOLD24BAR')}>{config.gallery.header24Karat}</Button>
            {config.gallery["show24KaratCoins"]?<Button variant='secondary' onClick={(e)=>changeTab(e,'GOLD24COINS')}>{config.gallery.header24KaratCoins}</Button>:null}
            <Button variant='secondary' onClick={(e)=>changeTab(e,'GOLD22COINS')}>{config.gallery.header22Karat}</Button>
          </Col>
          <Col sm={3}></Col>
        </Row>
          {
            config && Object.keys(config).length && currTab=="GOLD24BAR"?
            config["weightRange"].split(",").map((weight,ind)=>{
              if(weight!=config.gallery.imageNotPresent){
                return <GalleryUtil24 key={ind} weight={weight+"gms"}/>
              }
            })
            :null
          }
          {
            config && Object.keys(config).length && currTab=="GOLD22COINS"?
            config["weightRange22"].split(",").map((weight,ind)=>{
              if(weight!=config.gallery.imageNotPresent){
                return <GalleryUtil22 key={ind} weight={weight+"gms"}/>
              }
            })
            :null
          }
          {
            config && Object.keys(config).length && currTab=="GOLD24COINS"?
            config["weightRange24Coin"].split(",").map((weight,ind)=>{
              if(weight!=config.gallery.imageNotPresent){
                return <GalleryUtil24Coin key={ind} weight={weight+"gms"}/>
              }
            })
            :null
          }
      </Container>
    </div>
  );
}

export default Gallery;