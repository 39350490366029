import '../../App.css';
import './contact.css';

import React , {Component} from 'react';
import { Icon } from '@iconify/react';
import TextField from "../subComponents/TextField";
import {Row, Col} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {mapStateToProps, headerObj, getEnvironment} from '../../utility/common';
import axios from 'axios';

const UI_CONFIG = require('../../config/config.json')

class Contact extends Component{
  
  constructor(props){
    super(props);
    this.state = {
      name:'',
      subject:'',
      phoneNumber:'',
      message:'',
      errorMessage:'',
      successMessage:'',
      height : window.outerHeight - 50 
    }
  }

  handleInputChange = event => {  
    this.setState({
        [event.target.name]: event.target.value,
        errorMessage: ''
    })
  }

  formSubmit = (event) => {
    event.preventDefault()
    this.checkValidation()   
  }

  checkValidation = () => {
    const{name, subject, phoneNumber, message } = this.state;
    var errorMessage = this.state.errorMessage ;
    this.setState({successMessage:''});
    if(sessionStorage.getItem("cmJzX3VzZXI=")){
      errorMessage = this.props.config.value.contact.errorMsg.duplicateData;
      this.setState({
        name:'',
        subject:'',
        phoneNumber:'',
        message:''
      })
    }
    else if (name === '') {
      errorMessage = this.props.config.value.contact.errorMsg.name;
    } else if (subject === '') {
      errorMessage = this.props.config.value.contact.errorMsg.subject;
    }  else if  (phoneNumber === ''){
      errorMessage = this.props.config.value.contact.errorMsg.phone;
    }else {
      this.setState({
          errorMessage: ''
      }, () => this.saveProposalForm())
    }
    this.setState({
        errorMessage
    })
  }

  saveProposalForm =  () => {
    const state_vals = this.state;
    const data = {
      "customer_name": state_vals["name"],
      "mobile_number": state_vals["phoneNumber"],
      "subject": state_vals["subject"],
      "message": state_vals["message"]
    }
    sessionStorage.setItem("cmJzX3VzZXI=", window.btoa(state_vals["phoneNumber"]));
    axios.post(getEnvironment(window.location.host)+UI_CONFIG.sendMail,data, headerObj)
    .then(resp=>{
      this.setState({
        successMessage : this.props.config.value.contact.successMsg,
        errorMessage : '',
        name:'',
        subject:'',
        phoneNumber:'',
        message:''
      })
    })
    .catch(err=>{
      this.setState({
        successMessage : '',
        errorMessage : this.props.config.value.contact.errorMsg.somethingElse,
        name:'',
        subject:'',
        phoneNumber:'',
        message:''
      })
    })
  }
  openDirections = () =>{
    window.open(this.props.config.value.getDirection+this.props.config.value.contact.JewelName+","+this.props.config.value.contact.address.replace(" ","+"),"_blank")
  }
  render(){
    const styleObj={
      "height" : this.state.height
      }
    return(
      this.props.config.value && Object.keys(this.props.config.value).length===0?
      <Redirect to="/" />:<React.Fragment>
      <div className="container main-outer-container contact-form" style={styleObj}>
        <div>
          <iframe className="googleMap" src={this.props.config.value.contact.mapUrl} frameBorder="0" allowFullScreen></iframe>
        </div>
        <Row>
          <Col md={4}>
            <div className="info">
              <div className="address">
                <Icon className= "locationIcon" icon="bi:geo-alt" height="2rem" width="2rem" onClick={()=>this.openDirections()}>
                </Icon>
              </div>
              <div>
                <span>{this.props.config.value.contact.address}</span>
              </div>
            </div>
            <div className="info">
              <div className="email">
                <Icon className= "emailIcon" icon="bi:envelope" height="2rem" width="2rem"/>
              </div>
              <div>
                <span><a href={"mailto:"+this.props.config.value.contact.mailId}>{this.props.config.value.contact.mailId}</a></span>
              </div>
            </div> 
            <div className="info">
              <div className="phone">
                <Icon className= "mobIcon" icon="bi-phone" height="2rem" width="2rem"/>
              </div>
              <div>
              <span>
                {
                  this.props.config.value.contact.mobNo.map((mobNo,ind)=>{
                    if(ind%2==0 && ind +1 <this.props.config.value.contact.mobNo.length ){
                      return <span key={ind}><a href={"tel:"+mobNo}>{mobNo}</a> &nbsp;/&nbsp;</span>
                    }
                    else{
                      return <span key={ind}><a href={"tel:"+mobNo}>{mobNo}</a><br/></span>
                    }
                  })
                }
                </span>
              </div>
            </div> 
          </Col>
          <Col md={8}>
          <form onSubmit={this.formSubmit}>
            <Row>
              <Col md={6}>
                <TextField
                  placeholder={this.props.config.value.contact.placeHolders.name}
                  onChange={this.handleInputChange}
                  value={this.state.name}
                  name="name"
                  />
              </Col>
              <Col md={6}>
                <TextField
                  placeholder={this.props.config.value.contact.placeHolders.phone}
                  onChange={this.handleInputChange}
                  value={this.state.phoneNumber}
                  name="phoneNumber"
                  type="number"
                  maxLength="10"
                  />
              </Col>    
            </Row>
            <Row>
              <Col>
                <TextField
                  placeholder={this.props.config.value.contact.placeHolders.subject}
                  onChange={this.handleInputChange}
                  value={this.state.subject}
                  name="subject"
                  />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  placeholder={this.props.config.value.contact.placeHolders.msg}
                  multiline
                  onChange={this.handleInputChange}
                  value={this.state.message}
                  name="message"
                  />
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-4">
              <button className="btn btn-warning"  onClick={(event)=>this.formSubmit(event)}>{this.props.config.value.contact.sendMsg}</button>
            </div>
            <span className="d-flex justify-content-center">
              <span style={{ color: 'red' }}className="text-danger ml-2">{this.state.errorMessage}</span>
            </span>
            <span className="d-flex justify-content-center ">
            <span style={{ color: 'green' }} className="text-success ml-2">{this.state.successMessage}</span>
            </span>
          </form>
          </Col>
        </Row> 
      </div> 
    </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(Contact);