import '../../App.css';
import './becomeRetailer.css';

function BecomeRetailer() {
  return (
    <div className="becomeRetailer main-outer-container">
      Comming Soon
    </div>
  );
}

export default BecomeRetailer;