import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: []
};

export const retailerSlice = createSlice({
  name: 'retailer',
  initialState,
  reducers: {
    update: (state, action) => {
      state.value = action.payload;
    },
  },
});


export const { update } = retailerSlice.actions;

export const getRetailers = (state) => state.retailer.value;

export default retailerSlice.reducer;
