import React from 'react';
import ToolTipComponent from './Tooltipcomponent';
import { inputLimiter } from './helper_function';
    
    const TextField = ({disabled=false,placeholder, maxDate,minDate,fieldLabel,mandatory=false,validationMessage='',limitInput=false,multiline=false,bottom=false,toolTip=false,toolTipData='',...input}) => {
        
       function withToolTip(){
           return(
            <div style={{ display: 'flex' }}>
            {fieldLabel!==""?<span style={{fontSize:'12px'}} >{fieldLabel }{mandatory && <span style={{color:'red',fontSize:'20px',width:'20%'}}>*</span>}</span>:null}
                  <span className="tool-tip">
                  { toolTip ? <ToolTipComponent exclamationIcon id='1' data={toolTipData} /> : null}</span></div>
           )
       
           
       }

       function withOutToolTip(){
        return(
         <React.Fragment>
         {fieldLabel!==""?<label>{fieldLabel}{mandatory && <label style={{color:'red',fontSize:'20px',width:'10%'}}>*</label>}</label>:null}
                   { toolTip ? <ToolTipComponent exclamationIcon id='1' data={toolTipData} /> : null}
                   </React.Fragment>
        )
    
        
    }


        return(
       <React.Fragment>
        
        <div className="input-field form-inline">
            <div className="form-group">
                
                {toolTip?withToolTip():withOutToolTip()}
                {
                   
                    multiline?<textarea className="form-control" {...input} placeholder={placeholder} disabled = {(disabled)? "disabled" : ""}/>:
                    <input className="form-control" {...input}
                    onInput={inputLimiter}
                    max={maxDate}
                    min={minDate}
                    placeholder={placeholder}
                    disabled = {(disabled)? "disabled" : ""}
                    />
                }
                {validationMessage && <p className="errorMessage">{validationMessage}</p>}
                </div>       
            </div>
             
          </React.Fragment>
     )}


     export default TextField;