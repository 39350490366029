import '../../App.css';
import './header.css';
import { Navbar, Container,Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import logo from '../../assets/images/rbs_logo.png';
import { getConfig } from '../../app/configSlice';
import { useSelector } from 'react-redux';

const UI_CONFIG = require('../../config/config.json') 

function Header() {
  const config = useSelector(getConfig);
  const navLinkCollapse=(event)=>{
    var element = document.getElementById("homeTab")
    if(event.target.innerText !== config.header.home && event.target.innerText !== config.header.orderSpec){
      element.classList.remove("active");
    }
    if(event.target.innerText === config.header.home){
      element.classList.add("active")
    }
    if($("#responsive-navbar-nav").hasClass("show")){
      $("#responsive-navbar-nav").removeClass("show")
    }
  }
  return (
    <div className="header">
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand to="/">
            <img src={logo} className="custLogo" alt="rbs logo" height="50" width="50"/>
            <div className="custName">{config.customerName}</div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          {
            config && Object.keys(config).length?
            <Nav className="navHeader">
              <NavLink to="/" className="nav-link" onClick={(e)=>navLinkCollapse(e)} id="homeTab">{config.header.home}</NavLink>
              <NavLink to="/about" className="nav-link" onClick={(e)=>navLinkCollapse(e)}>{config.header.about}</NavLink>
              {UI_CONFIG["showLiveRatesTab"]?<NavLink to="/liveRate" className="nav-link" onClick={(e)=>navLinkCollapse(e)}>{config.header.liveRate}</NavLink>:null}
              <NavLink to="/todaysRate" className="nav-link" onClick={(e)=>navLinkCollapse(e)}>{config.header.todaysRate}</NavLink>
              <NavLink to="/gallery" className="nav-link" onClick={(e)=>navLinkCollapse(e)}>{config.header.gallery}</NavLink>
              <NavLink to="/mobileApp" className="nav-link" onClick={(e)=>navLinkCollapse(e)}>{config.header.mobileApp}</NavLink>
              <NavLink to="/buysell" className="nav-link" onClick={(e)=>navLinkCollapse(e)}>{config.header.buySell}</NavLink>
              <NavLink to="/contact" className="nav-link" onClick={(e)=>navLinkCollapse(e)}>{config.header.contact}</NavLink>
              <a className="nav-link specialTab" href= {config.header.orderSpecUrl} title={config.header.orderSpecTitle} target="_blank" onClick={(e)=>navLinkCollapse(e)}>{config.header.orderSpec}</a>
            </Nav>
          :null
          }
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;