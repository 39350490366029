import './popUp.css'
import React from 'react';
import { Modal} from 'react-bootstrap';

function MyVerticallyCenteredModal(props) {
    let dataFlag= false
    if(props.data){
        dataFlag=true
    }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                dataFlag?props.data.data.order.map((val,i)=>{
                    return <div key={"key"+i}>
                        {
                            val!=props.data.type?<h6>{val.replaceAll("_"," ").toUpperCase()}</h6>:null 
                        }
                        {
                            props.data.data[val].map((row,j)=>{
                                return <p key={"key"+i+j} className="text">{row}</p>
                            })
                        }   
                   </div> 
                }):null
            }
        </Modal.Body>
      </Modal>
    );
  }
  
export default function PopUp(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState();

    const getModalData = (showHide) =>{
        if(showHide){
            setModalData({"type":props.type,"data":props.data})
        }
        setModalShow(showHide)
    }
  
    return (
      <>
        <span onClick={() => getModalData(true)}>
          {props.title}
        </span>
  
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => getModalData(false)}
          title = {props.title}
          data = {modalData}
        />
      </>
    );
  }
