import React from 'react';
import './Tooltip.css'
const ToolTip = (props) => {
    return (
        <div id="tooltip" className="top" style={{ display: `${props.toolHoverId==props.id ? "inline-block": "none"}`}}>
            <div className="tooltip-arrow" />
            <div className="tooltip-label">{props.data}</div>
        </div>
    )
}
export default ToolTip;