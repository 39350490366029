import '../../App.css';
import './liveRate.css';

import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {mapStateToProps, headerObj} from '../../utility/common';
import { Container, Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Component } from 'react';
import Loader from "react-loader-spinner";
import { getLiveRates, update } from '../../app/liveRatesSlice';

const UI_CONFIG = require('../../config/config.json')

class LiveRate extends Component{

    constructor(props){
        super(props);
        this.state = {
            rates: {},
            currTime : new Date(),
            styleObj : {
                "height" : window.outerHeight - 300
              }
        }
    }
    interval;

    componentDidMount(){
        if(this.props.liveRates.value && Object.keys(this.props.liveRates.value).length>0){
            this.setState({
                rates: this.props.liveRates.value,
                currTime: new Date()
            });
        }
        else{
            this.getRates(UI_CONFIG.liveRateBasePath)
        }
        if(UI_CONFIG.relaodInterval !== 0){
            this.interval = setInterval(()=>{
                this.getRates(UI_CONFIG.liveRateBasePath);
            },UI_CONFIG.relaodInterval * 1000)
        }
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    getRates = (path) =>{
        axios.get(path,headerObj)
        .then(rates=>{
            this.setState({
                rates: rates.data,
                currTime: new Date()
            });
            this.props.dispatch(update(rates.data))
        })
        .catch(error=>{
            console.log(error.error)
        })
    }

    render(){
        return (
            this.props.config.value && Object.keys(this.props.config.value).length===0?
                <Redirect to="/" />:<div className="liveRate main-outer-container" style={this.state.styleObj}>
                    {
                        this.state.rates  && Object.keys(this.state.rates).length===0?
                            <Loader
                            type="TailSpin"
                            color="grey"
                            height={200}
                            width={200}
                            />:
                            <Container>
                                <Row>
                                    <Col className="header">{this.props.config.value.liveRate.headerText}</Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col className="date">{this.props.config.value.liveRate.lastUpdate} {this.state.currTime.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })} {this.state.currTime.toLocaleTimeString()}</Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover size="sm" id="liveRateTable">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col1"]}</th>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col2"]}</th>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col3"]}</th>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col4"]}</th>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col5"]}</th>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col6"]}</th>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col7"]}</th>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col8"]}</th>
                                                    <th>{this.props.config.value["liveRate"]["liveRateTable"]["col9"]}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.rates && Object.keys(this.state.rates).length!==0?
                                                    Object.keys(this.state.rates).map((product,ind)=>{
                                                        return <tr key={ind+"gold"}>
                                                            <td>{ind+1}</td>
                                                            <td>{this.props.config.value.liveRate.productMapping[product]}</td>
                                                            <td>{this.state.rates[product]["buy"]}</td>
                                                            <td>{this.state.rates[product]["sell"]}</td>
                                                            <td>{this.state.rates[product]["last"]}</td>
                                                            <td>{this.state.rates[product]["high"]}</td>
                                                            <td>{this.state.rates[product]["low"]}</td>
                                                            <td>{this.state.rates[product]["open"]}</td>
                                                            <td>{this.state.rates[product]["close"]}</td>
                                                        </tr>
                                                    }):null
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Container> 
                    }
            </div>
        )
    }
}


export default connect(mapStateToProps)(LiveRate);