import { Container,Row, Col,Table, Tab, Button } from 'react-bootstrap';
import '../../App.css';
import './todaysRate.css';
import { getConfig } from '../../app/configSlice';
import { update, getTodaysRate } from '../../app/todaysRateSlice';
import { Redirect } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import React, {useEffect} from 'react';
import Loader from "react-loader-spinner";
import axios from 'axios';
import { headerObj, getEnvironment } from '../../utility/common';
import { useState } from 'react';

const UI_CONFIG = require('../../config/config.json')

function TodaysRate() {
  const [bgHeight,setBgHeight] = useState(window.outerHeight-200)
  const calcTodaysRate916=(rate)=>{
    return Math.round((rate / 99.50) * 91.60)
  }
  const styleObj={
    "height" : bgHeight
  }
  const config = useSelector(getConfig);
  const todaysRateData = useSelector(getTodaysRate);
  const [active, setActive] = useState(true);
  const [todaysRate916, setodaysRate916] = useState(calcTodaysRate916(todaysRateData["todays_rate"]))
  const dispatch = useDispatch();
  useEffect(()=>{
    if(todaysRateData && Object.keys(todaysRateData).length===0){
      axios.get(getEnvironment(window.location.host)+UI_CONFIG.getTodaysRates, headerObj)
      .then(resp=>{
        dispatch(update(resp.data))
        setodaysRate916(calcTodaysRate916(resp.data['todays_rate']))
      })
      .catch(error=>{
        console.log(error.error)
      })
    }
  })
  
  const toggleView=(view)=>{
    if(view){
      setActive(false);
    }
    else{
      setActive(true)
    }
  }
  return (
    config && Object.keys(config).length===0?
    <Redirect to="/" />:<div className="todaysRate main-outer-container" style={styleObj}>
      {
        todaysRateData && Object.keys(todaysRateData).length===0 ?
        <Loader
          type="TailSpin"
          color="grey"
          height={200}
          width={200}
        />:
        <Container>
          <Row>
            <Col className="header">
            {config["todaysRate"]["header"]}
            <span className="toggleView" title="change View">
              {active?
              <Button variant="warning" onClick={()=>toggleView(true)}>{config["todaysRate"]["changeViewActive"]}</Button>
              :
              <Button variant="secondary" onClick={()=>toggleView(false)}>{config["todaysRate"]["changeViewInActive"]}</Button>
              }
            </span>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col className="date">
            {new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
            </Col>
          </Row>
          <br/>
          <Row>{
            active?
            <Col className="rateTableContainer">
            <Table striped bordered hover size="sm" className="rateTable">
              <thead>
                <tr>
                  <th>{config["todaysRate"]["rateTable"]["col1"]}</th>
                  <th>{config["todaysRate"]["rateTable"]["col2"]}</th>
                  <th>{config["todaysRate"]["rateTable"]["col3"]}</th>
                </tr>
              </thead>
              <tbody>
              {
                  config["weightRange"].split(",").map((weight,i)=>{
                    return <tr key={i}>
                      <td>{i+1}</td>
                      <td>{config["todaysRate"]["productPre"]} {weight} {config["todaysRate"]["productPost"]} </td>
                      <td>{(Number(todaysRateData["todays_rate"]) * weight + Number(config["todaysRate"]["making_charges"][weight])).toLocaleString()}{config["todaysRate"]["gstText"]}</td>
                    </tr>
                  })
                }
                {
                  config["weightRange24Coin"].split(",").map((weight,i)=>{
                    return <tr key={i}>
                      <td>{i+1+config["weightRange"].split(",").length}</td>
                      <td>{config["todaysRate"]["productPre24Coin"]} {weight} {config["todaysRate"]["productPost"]} </td>
                      <td>{(Number(todaysRateData["todays_rate"]) * weight + Number(config["todaysRate"]["making_charges"][weight])).toLocaleString()}{config["todaysRate"]["gstText"]}</td>
                    </tr>
                  })
                }
                {
                  config["weightRange22"].split(",").map((weight,i)=>{
                    return <tr key={i}>
                      <td>{i+1+config["weightRange"].split(",").length+config["weightRange24Coin"].split(",").length}</td>
                      <td>{config["todaysRate"]["productPre22"]} {weight} {config["todaysRate"]["productPost"]} </td>
                      <td>{(  todaysRate916 * weight + Number(config["todaysRate"]["making_charges22"][weight])).toLocaleString()}{config["todaysRate"]["gstText"]}</td>
                    </tr>
                  })
                }
              </tbody>
            </Table>
            </Col>:
            <React.Fragment>
              <Col>
            <Table striped bordered hover size="sm" className="rateTable">
              <thead>
                <tr>
                  <th>{config["todaysRate"]["rateTable"]["col1"]}</th>
                  <th>{config["todaysRate"]["rateTable"]["col2"]}</th>
                  <th>{config["todaysRate"]["rateTable"]["col3"]}</th>
                </tr>
              </thead>
              <tbody>
              {
                  config["weightRange24Coin"].split(",").map((weight,i)=>{
                    return <tr key={i}>
                      <td>{i+1}</td>
                      <td>{config["todaysRate"]["productPre24Coin"]} {weight} {config["todaysRate"]["productPost"]} </td>
                      <td>{(Number(todaysRateData["todays_rate"]) * weight + Number(config["todaysRate"]["making_charges"][weight])).toLocaleString()}{config["todaysRate"]["gstText"]}</td>
                    </tr>
                  })
                }
              </tbody>
              </Table>
            </Col>
            <Col>
            <Table striped bordered hover size="sm" className="rateTable">
              <thead>
                <tr>
                  <th>{config["todaysRate"]["rateTable"]["col1"]}</th>
                  <th>{config["todaysRate"]["rateTable"]["col2"]}</th>
                  <th>{config["todaysRate"]["rateTable"]["col3"]}</th>
                </tr>
              </thead>
              <tbody>
              {
                  config["weightRange"].split(",").map((weight,i)=>{
                    return <tr key={i}>
                      <td>{i+1}</td>
                      <td>{config["todaysRate"]["productPre"]} {weight} {config["todaysRate"]["productPost"]} </td>
                      <td>{(Number(todaysRateData["todays_rate"]) * weight + Number(config["todaysRate"]["making_charges"][weight])).toLocaleString()}{config["todaysRate"]["gstText"]}</td>
                    </tr>
                  })
                }
              </tbody>
              </Table>
            </Col>
            <Col>
            <Table striped bordered hover size="sm" className="rateTable">
              <thead>
                <tr>
                  <th>{config["todaysRate"]["rateTable"]["col1"]}</th>
                  <th>{config["todaysRate"]["rateTable"]["col2"]}</th>
                  <th>{config["todaysRate"]["rateTable"]["col3"]}</th>
                </tr>
              </thead>
              <tbody>
              {
                  config["weightRange22"].split(",").map((weight,i)=>{
                    return <tr key={i}>
                      <td>{i+1}</td>
                      <td>{config["todaysRate"]["productPre22"]} {weight} {config["todaysRate"]["productPost"]} </td>
                      <td>{(  todaysRate916 * weight + Number(config["todaysRate"]["making_charges22"][weight])).toLocaleString()}{config["todaysRate"]["gstText"]}</td>
                    </tr>
                  })
                }
              </tbody>
              </Table>
            </Col>
            </React.Fragment>
          }
          </Row>
        </Container>
      }
      
    </div>
  );
}

export default TodaysRate;