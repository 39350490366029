import '../../App.css';
import './footer.css';
import { Icon } from '@iconify/react';
import {Row, Col} from 'react-bootstrap';
import { getConfig } from '../../app/configSlice';
import { useSelector } from 'react-redux';
import PopUp from '../popUp/popUp';
import React from 'react';

function Footer() {
  const config = useSelector(getConfig);
  const openSocial = (platform) =>{
    window.open(config.social[platform],"_blank")
  }
  return (
    <React.Fragment>
      {
      config && Object.keys(config).length?
      <Row className="footer footer-basic">
        <Col className="social">
          <Icon  icon="akar-icons:instagram-fill" height="2rem" width="2rem" onClick={()=>openSocial("instagram")}></Icon>
          <Icon  icon="akar-icons:facebook-fill" height="2rem" width="2rem" onClick={()=>openSocial("facebook")}></Icon>
          <Icon  icon="akar-icons:twitter-fill" height="2rem" width="2rem" onClick={()=>openSocial("twitter")}></Icon>
          {/* <Icon  icon="akar-icons:snapchat-fill" height="2rem" width="2rem" onClick={()=>openSocial("snapchat")}></Icon> */}
        </Col>
        <Col>
        <ul className="list-inline footerLinks">
            {/* <li className="list-inline-item">{config.footer.desc></li> */}
            {/* <li className="list-inline-item">{config.footer.visitor}{config.visitorCount}</li> */}
            <li className="list-inline-item"><PopUp title={config.footer.terms} type="terms" data={config.terms}/></li>
            <li className="list-inline-item"><PopUp title={config.footer.privacy} type="privacy" data={config.privacy}/></li> 
        </ul>
        </Col>
        <Col>
        <p className="copyright">{config.customerName} {config.footer.rights}</p>
        </Col>
      </Row>
      :null
      }
    </React.Fragment>
  );
}

export default Footer;