import '../../App.css';
import './home.css';
import image_1 from '../../assets/images/gold_image_1.jpg';
import image_2 from '../../assets/images/gold_image_2.jpg';
import image_3 from '../../assets/images/gold_image_3.jpg';
import image_4 from '../../assets/images/gold_image_4.jpg';
import image_5 from '../../assets/images/gold_image_5.jpg';
import image_6 from '../../assets/images/gold_image_6.png';
import image_7 from '../../assets/images/gold_image_7.jpg';
import image_8 from '../../assets/images/gold_image_8.jpg';
import image_9 from '../../assets/images/gold_image_9.jpg';
import Loader from "react-loader-spinner";
import { Container } from 'react-bootstrap';
import React, { useState,useEffect } from 'react';
import { update, getConfig } from '../../app/configSlice';
import { update as todaysRateUpdate, getTodaysRate } from '../../app/todaysRateSlice';
import { update as getRetailersUpdate, getRetailers } from '../../app/retailerSlice';
import { update as liveRatesUpdate, getLiveRates } from '../../app/liveRatesSlice'
import { useSelector, useDispatch } from 'react-redux';
import BackgroundSlider from 'react-background-slider';
import axios from 'axios';
import { headerObj, getEnvironment } from '../../utility/common';

const UI_CONFIG = require("../../config/config.json")
const CONFIG_DATA = require("../../config/config_Data.json")

function Home() {
  const config = useSelector(getConfig);
  const todaysRateData = useSelector(getTodaysRate);
  const retailerData = useSelector(getRetailers);
  const liveRates = useSelector(getLiveRates);
  const dispatch = useDispatch();
  useEffect(() => {
    const ENV = getEnvironment(window.location.host)
    if(config && Object.keys(config).length === 0){
      // UI Configs
      dispatch(update(CONFIG_DATA))
      axios.get(ENV+UI_CONFIG.getConfig, headerObj)
      .then(resp=>{
        dispatch(update(resp.data))
      })
      .catch(error=>{
        console.log(error.error)
      })

      if(todaysRateData && Object.keys(todaysRateData).length===0){
        // get Todays Rate
        axios.get(ENV+UI_CONFIG.getTodaysRates, headerObj)
        .then(resp=>{
          dispatch(todaysRateUpdate(resp.data))
        })
        .catch(error=>{
          console.log(error.error)
        })
      }
      if(retailerData && Object.keys(retailerData).length===0){
        // get retailers Data
        axios.get(ENV+UI_CONFIG.getRetailers, headerObj)
        .then(resp=>{
          dispatch(getRetailersUpdate(resp.data))
        })
        .catch(error=>{
          console.log(error)
        })
      }

      if(UI_CONFIG["showLiveRatesTab"] && liveRates && Object.keys(liveRates).length===0){
        axios.get(UI_CONFIG.liveRateBasePath,headerObj)
        .then(resp=>{
            dispatch(liveRatesUpdate(resp.data))
        })
        .catch(error=>{
            console.log(error.error)
        })
      }
    }
  })
  useEffect(() => {
    
  })
  const [bgHeight,setBgHeight] = useState(window.outerHeight-100)
  const styleObj={
    "height" : bgHeight
  }
  return (
    <div className="home main-outer-container" style={styleObj}>
        {
          config && Object.keys(config).length?<React.Fragment>
          <BackgroundSlider
            images={[image_1, image_2,image_3,image_4,image_5, image_6,image_7,image_8,image_9]}
            duration={4}
            transition={1} 
          />
          <Container>
            <h1 className="heading">{config["home"]["header"]}</h1>
            <marquee className="bottom"> {config["home"]["marquee"]}</marquee>
          </Container>
          </React.Fragment>
          :<Loader
          type="TailSpin"
          color="grey"
          height={200}
          width={200}
        />
        }
    </div>
  );
}

export default Home;