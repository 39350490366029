import { configureStore } from '@reduxjs/toolkit';
import configReducer from './configSlice';
import retailerReducer from './retailerSlice';
import todaysRateReducer from './todaysRateSlice';
import liveRatesReducer from './liveRatesSlice';

export const store = configureStore({
  reducer: {
    config: configReducer,
    retailer: retailerReducer,
    todaysRate: todaysRateReducer,
    liveRates: liveRatesReducer
  },
});
