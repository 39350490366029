import '../../App.css';
import './mobileApp.css';
import { Container } from 'react-bootstrap';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { getConfig } from '../../app/configSlice';
import { useSelector } from 'react-redux';

function MobileApp() {
  const config = useSelector(getConfig)
  const [bgHeight,setBgHeight] = useState(window.outerHeight)
  const styleObj={
    "height" : bgHeight
  }
  return (
    config && Object.keys(config).length===0?
    <Redirect to="/" />:<div className="mobileApp main-outer-container" style={styleObj}>
      <Container>
      <h1>{config["mobileApp"]["header"]}</h1>
      </Container>
    </div>
  );
}

export default MobileApp;