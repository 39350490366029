export const inputLimiter = event => {
    if(event.target.maxLength>0){
      if(event.target.value.length > event.target.maxLength){
        return event.target.value = event.target.value.slice(0, event.target.maxLength)
    }}}

    export const removeByAttr = function(arr, attr, value){
      var i = arr.length;
      while(i--){
         if( arr[i] 
             && arr[i].hasOwnProperty(attr) 
             && (arguments.length > 2 && arr[i][attr] === value ) ){ 
             arr.splice(i,1);
         }
      }
      return arr;
  }