import '../../App.css';
import './buySell.css';
import React from "react";
import { Row, Col, Container,Button } from 'react-bootstrap';
import GetRetailer from '../retailer/retailer';
import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import Select from 'react-select';
import axios from 'axios';
import {connect} from 'react-redux';
import {mapStateToProps , headerObj, getEnvironment} from '../../utility/common';
import { getRetailers, update } from '../../app/retailerSlice';

const UI_CONFIG = require("../../config/config.json")

class BuySell extends React.Component {
  constructor(props){
      super(props);
      this.state={
        retailerDataAll : [],
        step : 100,
        currInd : 100,
        hasMoreData : false,
        currLen : 0,
        selectedRetailData : []
      }
  }
  componentDidMount(){
    if(this.props.retailer.value && this.props.retailer.value.length>0){
      this.setState({
        retailerDataAll : this.props.retailer.value,
        currLen : this.props.retailer.value.length,
        selectedRetailData : this.props.retailer.value.slice(0,100)
      })
    }
    else{
      axios.get(getEnvironment(window.location.host).getRetailers, headerObj)
      .then(respose=>{
        this.setState({
          retailerDataAll : respose.data,
          currLen : respose.data.length,
          selectedRetailData : respose.data.slice(0,100) 
        })
        this.props.dispatch(update(respose.data))
      })
      .catch(error=>{
        console.log(error)
      })
    }
  }

  applyRetailFilters =(retailerList)=>{
    if(retailerList==null || retailerList.length==0){
      this.clearFilters();
    }
    else{
      let newRetailList=[];
      retailerList.map(name=>
        newRetailList.push(name.value))
      let filtVals=this.state.retailerDataAll.filter(
        (v)=>{ 
          return newRetailList.indexOf(v.address.state) >= 0;
        })  
      this.setState({
        selectedRetailData : filtVals,
        currLen : filtVals.length,
      })
      if(filtVals.length<this.state.step){
        this.setState({
          hasMoreData: false
        })
      }
      else{
        this.setState({
          hasMoreData: true
        })
      }
    }
  }

  clearFilters =()=>{
    this.setState({
      currInd : this.state.step,
      selectedRetailData : this.state.retailerDataAll.slice(0,this.state.step),
      currLen : this.state.retailerDataAll.length,
      hasMoreData : false
    })
  }

  loadData =()=>{
    let moreData = true;
    let maxRight = this.state.currInd+this.state.step;
    if(maxRight>=this.state.currLen){
      maxRight = this.state.currLen
      moreData = false
    }
    this.setState({
      selectedRetailData : this.state.retailerDataAll.slice(0,maxRight),
      currInd: maxRight,
      hasMoreData : moreData
    })
  }
  render(){
    const {retailerDataAll, step, currInd, hasMoreData, currLen , selectedRetailData} = this.state
    let stateOccurenceList = [];
    retailerDataAll.forEach((retailer)=>{
      if(stateOccurenceList.some((val)=>{ return val["state"] == retailer["address"]["state"]})){
      stateOccurenceList.forEach((k)=>{
          if(k["state"] === retailer["address"]["state"]){ 
            k["occurrence"]++
          }
      })  
      }else{
        let obj = {}
        obj["state"] = retailer["address"]["state"]
        obj["occurrence"] = 1
        stateOccurenceList.push(obj);
      }
    });
    let stateDropdownList=[];
    stateOccurenceList.forEach(state=>{
    stateDropdownList.push({value:state["state"],label:state["state"]+" - "+state["occurrence"]})
    });
    const len = selectedRetailData.length;
    let rowData = []
    let c=0
    for (var i=0; i<len/2;i++){
      rowData.push(<Row key={i}>
        <GetRetailer retailer={selectedRetailData[c++]} key={"key_"+i+"0"}/>
        <GetRetailer retailer={selectedRetailData[c++]} key={"key_"+i+"1"}/>
        </Row>)
    }
    return (
      this.props.config.value && Object.keys(this.props.config.value).length===0?
        <Redirect to="/" />:<React.Fragment>
        <div className="buySell main-outer-container">
          <Container>
            {/* <NavLink to="/becomeRetailer">
              <div className="wantRetailer">{this.props.config.value["buySell"]["wantRetailer"]}</div>
            </NavLink> */}
            <div className="buySellHeader">{this.props.config.value["buySell"]["buyWhere"]}</div>
            <Row className="filterSection">
              <Col sm={3}>
                <strong>{this.props.config.value["buySell"]["totalRetailer"]}</strong>  : <span>{retailerDataAll.length}</span>
              </Col>
              <Col sm={3}>
              <strong>{this.props.config.value["buySell"]["currSelected"]}</strong>  : <span>{currLen}</span>
              </Col>
              <Col sm={6} className="filtersDropdown">
              <strong>{this.props.config.value["buySell"]["stateSelection"]}</strong> : &nbsp;
                {
                  <span><Select
                    isMulti
                    name="name"
                    options={stateDropdownList}
                    className="basic-multi-select"
                    onChange={val => this.applyRetailFilters(val)}
                  /></span>
                }
              </Col>
              </Row>
              <Row>
              <Col>
                {
                rowData.length===0 ?
                  <Loader
                  type="TailSpin"
                  color="grey"
                  height={200}
                  width={200}
                  />:
                  rowData
                } 
              </Col>
              <Row>
                <Col></Col>
                <Col>
                {
                  hasMoreData?<Button variant="primary" className="next" onClick={e=>this.loadData()}>{this.props.config.value["buySell"]["loadMore"]}</Button>:null
                }
                </Col>
                <Col></Col>
              </Row>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(BuySell);